@config "../tailwind.config.js";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Mulish", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

@keyframes changeTuneToTive {
  0% {
    content: "tune";
  }
  50% {
    opacity: 0.5;
  }
  100% {
    content: "tive";
  }
}

.animate-tune::after {
  content: "";
  animation: changeTuneToTive 2s linear infinite alternate;
}

@keyframes changeHeadline {
  0% {
    content: "Begin Your Path to Growth";
  }
  50% {
    opacity: 0.6;
  }
  100% {
    content: "Ignite Positivity and Inspiration";
  }
}

.animate-headline::after {
  content: "";
  animation: changeHeadline 5s linear infinite alternate;
}
