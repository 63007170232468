footer {
  padding: 1rem;
  text-align: center;
  font-size: 0.875rem;
  color: #666;
}

footer a {
  color: #666;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
